import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import bs58 from 'bs58';



class AdminLoginModule extends Component {
    constructor(props){
        super(props);
        this.state = {
            navigate: false,
            message: 'unauthorized'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.connectWalletAndSignMessage = this.connectWalletAndSignMessage.bind(this);

    }
    

    connectWalletAndSignMessage = async () => {
        try {
            const { solana } = window;
            if (solana && solana.isPhantom) {
                const response = await solana.connect(); // Connect to the wallet
                console.log('Connected with Public Key:', response.publicKey.toString());
      
                const message = "Solana's keys light the path - Flame Coin's gate awaits"
                const encodedMessage = new TextEncoder().encode(message);
                const signedMessage = await solana.signMessage(encodedMessage, "utf8");
                const signatureBase58 = bs58.encode(signedMessage.signature);
    
                const verificationResponse = await fetch('https://services.flamecoin.io/api/admin/verify', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        publicKey: response.publicKey.toString(),
                        signature: signatureBase58,
                        message: message
                    }),
                });
                if (verificationResponse.ok) {
                    const { token } = await verificationResponse.json();
                    sessionStorage.setItem('admin', true)
                    sessionStorage.setItem('authToken', token);
                    this.setState({ navigate: true }); 
                   <Navigate to="/admin/dashboard" replace />;
                } else {
                    const errorData = await verificationResponse.json();
                    this.setState({ message: errorData.error || 'Failed to authenticate.' });
                }
            } else {
                this.setState({ message: 'Phantom wallet not found. Please install it.' });
            }
        } catch (error) {
            console.error('Failed to connect with Phantom wallet:', error);
            this.setState({ message: 'Failed to connect with Phantom wallet.' });
        }
    };

    handleSubmit(event) {
        event.preventDefault();
        this.connectWalletAndSignMessage();
    }
    
    


    render() { 
        if (this.state.navigate) {
            return <Navigate to="/admin/dashboard" replace />;
        }
        return (
            <section className="banner-section">
                <div className="container">

<div className="section-header">           
<h2>Only the chosen few</h2>
<div className="section-wrapper">
    <div className="row align-items-end">
        <section className="page-header-section style-1" >
            <div className="banner-content">
                <div className="account-wrapper">
                    <form className="account-form" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <button className="d-block lab-btn" type="submit"><span>Step Into the Circle</span></button>
                        </div>
                    </form>
                </div>
            </div>
         </section>
                                        <h3>Paths of light to secrets known, treasures for the wise</h3>
                                  
                                </div>    </div>
                     
                        

                        

                            </div>
                        </div>
                  
          
            </section> 
        );
    }
}
 
export default AdminLoginModule;