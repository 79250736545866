import React, { Component } from 'react';
import Header from '../component/layout/header';
import FooterSection from '../component/layout/footer';
import Benefits from '../component/section/benefits';
import AboutFirst from '../component/section/about-first';
import PreRegistration from '../component/section/preregister';

class Landing extends Component {
    render() {
        return (
            <div>
                <Header />
                <PreRegistration />
                <Benefits />
                <AboutFirst />
                <FooterSection />

            </div>
        );
    }
}

export default Landing;