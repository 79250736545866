import React, { Component } from 'react';
import FooterSection from '../component/layout/footer';
import UserHeader from '../component/layout/userheader';


const tableStyle = {
    maxHeight: '300px',
    overflowY: 'auto',
    textAlign: 'left',

  };
  
  const thTdStyle = {
    padding: '16px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd'
  };
  
  const thStyle = {
    background: ''
  };

  
class UserDashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            totalTokens: 0,
            tokenLedger : [],
            email: '',
            referred_email: '',
            isLoading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    async componentDidMount() {
        try {
          const email = sessionStorage.getItem('email');
          const response = await fetch('https://services.flamecoin.io/api/user/ledger', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
      });

          const jsonData = await response.json();
          
          if (response.ok) {
            sessionStorage.setItem('totalTokens', jsonData.totalTokens);
            sessionStorage.setItem('ledgerTokens', JSON.stringify(jsonData.ledgerRecord));
            this.setState({
                totalTokens: jsonData.totalTokens,
                tokenLedger: jsonData.ledgerRecord, 
                email: email,// Assuming your API response structure has a data array
            });
          } else {
            this.setState({
              message: 'Error accessing data, try again.'
            });
          }
        } catch (error) {
          this.setState({
            message: 'An error occurred while trying to retrieve the data. Please try again.'
          });
        }
      }

      async handleSubmit(event) {
        event.preventDefault();
        const { email, referred_email } = this.state;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is valid
        if (!emailRegex.test(referred_email)) {
            this.setState({
                message: 'Please enter a valid email address.'
            });
            return; // Stop the function if the email is not valid
        }

        this.setState({isLoading: true});

        try {
            const response = await fetch('https://services.flamecoin.io/api/user/refer-someone', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, referred_email }),
            });
        
            if (response.ok) {
                this.setState({
                    message: 'SUCCESS - Feel free to referr another friend!',
                    referred_email: '',
                    isLoading: false,

                });
            } else {
                // Assuming the server responds with a status code in the 4xx-5xx range if there's an error
                this.setState({
                    message: 'An error occurred while submitting the form. Please try again.',
                    isLoading: false,
                });
            }
        } catch (error) {
            // Handle network errors or other exceptions
            this.setState({
                message: 'An error occurred while submitting the form. Please try again.',
                isLoading: false,
            });
        }
    }

    
    render() {

        const { tokenLedger, message, isLoading } = this.state;

        return (
            <div>
                <UserHeader />
                <section className="banner-section">
                    <div className="container">
                        {/* Row for Table and Refer & Earn sections */}
                        <div className="row section-padding">

                               {/* Column for the Refer & Earn Section */}
                            <div className="col-sm-12 col-lg-4"> {/* Adjusted to take 35% of the row */}
                                <h3>🔥 Refer & Earn 🔥</h3>
                                <div className="account-wrapper">
                                    <span className="d-block cate pt-10">Refer your friends and earn 200 $FLM when they join!</span><br />
                                    <form onSubmit={this.handleSubmit} className="account-form">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email_prereg"
                                                value={this.state.referred_email}
                                                onChange={(e) => {this.setState({referred_email: e.target.value});}}
                                                placeholder="Friend's Email*"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button className="d-block lab-btn" type="submit">
                                                <span>{isLoading ? 'Loading...' : 'Refer!'}</span>
                                            </button>
                                            <p>{message}</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* Column for the Tokens Earned Table */}
                            <div className="col-sm-12 col-lg-8"> {/* Adjusted to take 65% of the row */}
                               <h3>🔥 Tokens Earned 🔥</h3>
                                <div className="table-wrapper">
                                    <div style={tableStyle}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ ...thTdStyle, ...thStyle }}>Date</th>
                                                    <th style={{ ...thTdStyle, ...thStyle }}>Description</th>
                                                    <th style={{ ...thTdStyle, ...thStyle }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tokenLedger.map(item => (
                                                    <tr key={item.description}>
                                                        <td style={thTdStyle}>{new Date(item.created_at).toLocaleDateString()}</td>
                                                        <td style={{ ...thTdStyle, textAlign: 'right' }}>{item.description}</td>
                                                        <td style={{ ...thTdStyle, textAlign: 'right' }}>{item.amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
    <div className="row section-padding-sm"></div>
    <div className="table-separator"></div>
    <div className="row section-padding-sm"></div>
                            <div className="col-sm-12 col-lg-4"> 
                                <h3>🔥 Buy Tokens 🔥</h3><br />
                                <div className="account-wrapper">
                                    <span className="d-block cate pt-10">Coming Soon</span>
                                </div>
                            </div>
                        </div>
             
                </section>
                <FooterSection />
            </div>
        );
    }}

export default UserDashboard;