import React, { Component } from 'react';
import {Link} from 'react-router-dom';



class FooterSection extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }
    render() {
        
        return (
            <footer className="footer-section">
              
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-bottom-content text-center">
                                    <p>&copy; 2024 <Link to="/">FLM$</Link> - Ignite Passion.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterSection;