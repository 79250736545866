import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Header from '../component/layout/header';
import FooterSection from '../component/layout/footer';

const title = "Login";

class LoginPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            navigate: false,
            isLoading: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    async handleSubmit(event) {
        event.preventDefault();
        const { email, password } = this.state;
        sessionStorage.setItem('email', email);


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is valid
        if (!emailRegex.test(email)) {
            this.setState({
                message: 'Please enter a valid email address.'
            });
            return; // Stop the function if the email is not valid
        }

        this.setState({isLoading: true});

        try {
            const response = await fetch('https://services.flamecoin.io/api/user/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });
        
            if (response.ok) {
                const { token, name, userId } = await response.json();
                
                sessionStorage.setItem('authToken', token);
                sessionStorage.setItem('name', name);
                sessionStorage.setItem('userId', userId);
                this.setState({
                    email: '',
                    message: 'logging you in!',
                    navigate: true,
                    isLoading: false,
                });
                <Navigate to="/user/dashboard" replace />;

            } else {
                this.setState({
                    message: 'Something went wrong. Please try again',
                    isLoading: false,
                });
            }
        } catch (error) {
            
            this.setState({
                message: 'Something went wrong. Please try again.'
            });
        }
    }
    

    render() {
        if (this.state.navigate) {
            return <Navigate to="/user/dashboard" replace />;
        }
        const { message, isLoading }= this.state;
        return (
            <div>
                <Header />
    
                <section className="banner-section">
                <div className="container">

<div className="section-header"> 
</div>
                    <div className="row"> 

                    
                 
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>

                           
                            <form onSubmit={this.handleSubmit} className="account-form">

                                
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={this.state.email}
                                        onChange={(e)=>{this.setState({email: e.target.value});}}
                                        placeholder="Your email *" 
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="password"
                                        name="password"
                                        id="item03"
                                        value={this.state.password}
                                        onChange={(e)=>{this.setState({password: e.target.value});}}
                                        placeholder="Password *"
                                        required
                                    />
                                </div>
                              
                                <div className="form-group">
                                    <button className="d-block lab-btn"><span>{isLoading ? 'Loading...' : 'Login'}</span></button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Not Registered Yet? <Link to="/register">Register</Link></span>
                                          <p>{message}</p>              </div>
                        </div></div>
                        
                        
                        
                    
                        
                            </div>
                                        
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default LoginPage;