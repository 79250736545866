import React, { Component } from 'react';

class SubscriberTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      subscribers: [],
      totalSubscribers: 0,
      message: '',
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch('https://services.flamecoin.io/api/admin/data/subscribers');
      const jsonData = await response.json();
      
      if (response.ok) {
        this.setState({
          subscribers: jsonData.data || [],
          totalSubscribers: jsonData.count // Assuming your API response structure has a data array
        });
      } else {
        this.setState({
          message: 'Error accessing data, try again.'
        });
      }
    } catch (error) {
      this.setState({
        message: 'An error occurred while trying to retrieve the data. Please try again.'
      });
    }
  }

  render() {
    const { subscribers, totalSubscribers, message } = this.state;

    
    return (
      <section className="banner-section">
        <div className="container">
          <div className="section-header">
            <h2>🔥 This world is on fire 🔥</h2>
          </div>
          <div className="section-wrapper">
            <div className="row align-items-end">
              <section className="page-header-section style-1">
              <h3>{totalSubscribers}</h3>
                <div className="banner-content">
                  <div className="account-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }}> Date</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscribers.map(subscriber => (

                          
<tr key={subscriber.email}> 
<td style={{ textAlign: 'left' }}>{subscriber.email}</td>
<td style={{ textAlign: 'right' }}>{new Date(subscriber.subscribed_at).toLocaleDateString()}</td>
</tr>

                        ))}
                      </tbody>
                    </table>
                    {message && <p>{message}</p>}
                  </div>
                </div>
                <h3>always hold</h3>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SubscriberTable;
