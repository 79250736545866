import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Header from '../component/layout/header';
import FooterSection from '../component/layout/footer';


const title = "Register Now";
  

class RegisterPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirm: '',
            referrer_code: '',
            message: '',
            navigate: false,
            isLoading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    async handleSubmit(event) {
        event.preventDefault();
        const { name, email, password, password_confirm, referrer_code } = this.state;


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is valid
        if (!emailRegex.test(email)) {
            this.setState({
                message: 'Please enter a valid email address.'
            });
            return; // Stop the function if the email is not valid
        }

        this.setState({isLoading: true });

        try {
            const response = await fetch('https://services.flamecoin.io/api/user/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, name, password, password_confirm, referrer_code }),
            });
        
            if (response.ok) {
                this.setState({
                    email: '',
                    message: 'Success! redirecting to login'  ,
                    navigate: true,
                    isLoading: false,
                });
                <Navigate to="/login" replace />;
            } else {
                this.setState({
                    message: 'Email address already exists, or there is an error. Please try again',
                    isLoading: false,
                });
            }
        } catch (error) {
            
            this.setState({
                message: 'An error occurred while submitting the form. Please try again.'
            });
        }
    }


    render() {
        if (this.state.navigate) {
            return <Navigate to="/login" replace />;
        }
        const { message, isLoading}= this.state;
        return (
            <div>
                <Header />
    
                <section className="banner-section">
                <div className="container">

<div className="section-header"> 
</div>
                    <div className="row"> 

                    
                 
                 <div className="col-lg-5">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>

                           
                            <form onSubmit={this.handleSubmit} className="account-form">

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.name}
                                        onChange={(e)=>{this.setState({name: e.target.value});}}
                                        placeholder="Your Name *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={this.state.email}
                                        onChange={(e)=>{this.setState({email: e.target.value});}}
                                        placeholder="Your email *" 
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="password"
                                        name="password"
                                        id="item03"
                                        value={this.state.password}
                                        onChange={(e)=>{this.setState({password: e.target.value});}}
                                        placeholder="Password *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="password"
                                        name="password_confirm"
                                        id="item04"
                                        value={this.state.password_confirm}
                                        onChange={(e)=>{this.setState({password_confirm: e.target.value});}}
                                        placeholder="Confirm Password *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        name="referral_code"
                                        id="item02"
                                        value={this.state.referrer_code}
                                        onChange={(e)=>{this.setState({referral_code: e.target.value});}}
                                        placeholder="Refereral Code" 
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="d-block lab-btn"><span>{isLoading ? 'Loading...' : 'Register'}</span></button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Already registred? <Link to="/login">Login</Link></span>
                                          <p>{message}</p>              </div>
                        </div></div>
                        
                        
                        
                    
                        <div className="col-lg-6">
                    
                    <h3>🔥 Join Early, Gain More 🔥</h3>
                    <p>Register now to receive 1,000 FREE Flame Coins at launch and secure early access to additional coins. By joining our exclusive community of early adopters, you are not only getting a head start in the exciting world of Flame App, but you are also securing your place at the forefront of a revolution in digital dating.</p><br />

                    <h3>🔥 Why Register Now? 🔥</h3>
                    <p>As an early member, you’ll benefit from being among the first to explore and shape the Flame App experience. Your early feedback and interactions will help us refine the features and functionalities that make dating not just fun but rewarding. Plus, the Flame Coins you earn can be used for special in-app advantages, such as accessing premium features and unlocking unique experiences.</p><br />

                    <h3>🔥 The Journey Starts Here 🔥</h3>
                    <p>Becoming a part of Flame App now means you’re setting yourself up for a richer, more engaging online dating experience. Don’t miss out on this opportunity to turn your interactions into real rewards. Sign up today, claim your free Flame Coins, and be prepared to ignite your dating life like never before!</p>
                    </div>
                        
                        
                        </div>    </div>
                                        
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default RegisterPage;