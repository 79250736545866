import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import logo from './assets/images/logo/flm_logo_header.png';

// Component to protect routes for admin access
const AdminProtectedRoute = ({ children }) => {
    // State to manage the validity of the admin session
    const [isValid, setIsValid] = useState(null);  // null: checking, false: invalid, true: valid

    // Retrieve admin session data from sessionStorage
    const token = sessionStorage.getItem('authToken');
    const admin = sessionStorage.getItem('admin');

    useEffect(() => {
        // Function to verify the admin token
        const verifyToken = async () => {
            try {
                const response = await fetch('https://services.flamecoin.io/api/admin/session', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ token }),
                });

                // Set validity based on server response
                setIsValid(response.ok);
            } catch (error) {
                console.error('Error verifying token:', error);
                setIsValid(false);  // Assume invalid on error
            }
        };

        if (!token || !admin) {
            setIsValid(false); // Set as invalid if token or admin flag is missing
        } else {
            verifyToken();  // Validate token if present
        }
    }, [token, admin]); // React to changes in token or admin flag

    // Conditional rendering based on authentication status
    if (isValid === null) {
        return <div className="account-wrapper"><img src={logo} alt="logo" /></div>; // Loading state
    } else if (!isValid) {
        return <Navigate to="/admin/login" replace />; // Redirect to login if not valid
    }

    return children;  // Render protected route's children if valid
};

export default AdminProtectedRoute;
