import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MembersPage from './pages/members';
import ScrollToTop from './component/layout/scrolltop'; // Ensure the path is correct
import AdminLogin from './pages/adminlogin';
import AdminDashboard from './pages/admindashboard';
import AdminProtectedRoute from "./AdminProtectedRoute"; // Ensure the path is correct
import UserProtectedRoute from './UserProtectedRoute';
import AboutFirst from './component/section/about-first';
import RegisterPage from './pages/signin';
import Landing from './pages/landing';
import ContactPage from './pages/contact';
import LoginPage from './pages/login';
import UserDashboard from './pages/userdashboard';
import { Analytics } from "@vercel/analytics/react"

function App() {
    return (
        <Router>
            <ScrollToTop /><Analytics/>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/members" element={<MembersPage />} />
                <Route path="/admin/login" element={<AdminLogin />} />
				<Route path="/about" element={<AboutFirst />} />
				<Route path ="/login" element={<LoginPage />} />
				<Route path ="/register" element={<RegisterPage />} />
				<Route path ="/contact" element={<ContactPage />} />
                <Route 
                    path="/admin/dashboard" 
                    element={
                        <AdminProtectedRoute>
                            <AdminDashboard />
                        </AdminProtectedRoute>
                    } 
                />
                 <Route 
                    path="/user/dashboard" 
                    element={
                        <UserProtectedRoute>
                            <UserDashboard />
                            </UserProtectedRoute>
                    } 
                />
            </Routes>
        </Router>
    );
}

export default App;
