import React, { Component } from 'react';


const title = "FlameCoin Is Your Ticket to the Top of the Dating Scene";
const subtitle = "What is FlameCoin?";

let WorkListContent = [
    {
        imgUrl: 'assets/images/work/01.png',
        imgAlt: 'work thumb',
        step: 'step',
        stepCount: '01',
        title: 'Heat Up Your Love Life',
        desc: 'Dive into a world where your charm, engagements, and connections turn into Flame Coin rewards. The more desirable your interactions, the higher you climb on the dating leaderboard',
    },
    {
        imgUrl: 'assets/images/work/02.png',
        imgAlt: 'work thumb',
        step: 'step',
        stepCount: '02',
        title: 'Exclusive Experiences',
        desc: 'Stand out in the dating scene. Reaching the top of the leaderboard not only boosts your desirability but also unlocks exclusive, luxurious dating experiences and rewards',
    },
    {
        imgUrl: 'assets/images/work/03.png',
        imgAlt: 'work thumb',
        step: 'step',
        stepCount: '03',
        title: 'Supercharge Your Rewards',
        desc: 'The more you engage, the more you ascend the social ladder, the hotter and increasingly exclusive benefits you will  unlock.  it is not just a race to be the most desired but also The only real way to show to the all world your true unique value',
    },
]

class Benefits extends Component {
    state = {  } 
    render() { 
        return (
            <section className="work-section padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h4 className="theme-color">{subtitle}</h4>
                        <h2>{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-5">
                            {WorkListContent.map((val, i) => (
                                <div className="col-lg-4 col-sm-6 col-12 px-4" key={i}>
                                    <div className="lab-item work-item">
                                        <div className="lab-inner text-center">
                                            <div className="lab-thumb">
                                                <div className="thumb-inner">
                                                    <img 
                                                        src={`${val.imgUrl}`} 
                                                        alt={`${val.imgAlt}`} 
                                                    />
                                                    <div className="step">
                                                        <span>{val.step}</span>
                                                        <p>{val.stepCount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lab-content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Benefits;