import React, { Component } from 'react';
import Header from '../component/layout/header';
import FooterSection from '../component/layout/footer';
import SubscriberTable from '../component/section/subscriberstable';

class AdminDashBoard extends Component {
    render() {
        return (
            <div>
                <Header />
                <SubscriberTable />
                <FooterSection />

            </div>
        );
    }
}

export default AdminDashBoard;