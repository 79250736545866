import React, { Component } from 'react';


class PreRegistration extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            message: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async handleSubmit(event) {
        event.preventDefault();
        const { email } = this.state;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is valid
        if (!emailRegex.test(email)) {
            this.setState({
                message: 'Please enter a valid email address.'
            });
            return; // Stop the function if the email is not valid
        }

        try {
            const response = await fetch('https://services.flamecoin.io/api/user/register-interest', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
        
            if (response.ok) {
                this.setState({
                    message: 'SUCCESS - we will email you with instructions before launch!',
                    email: ''  // Clear the email state after successful registration
                });
            } else {
                // Assuming the server responds with a status code in the 4xx-5xx range if there's an error
                this.setState({
                    message: 'Email address already exists, or there is an error. Please try again'
                });
            }
        } catch (error) {
            // Handle network errors or other exceptions
            this.setState({
                message: 'An error occurred while submitting the form. Please try again.'
            });
        }
    }

    
    


    render() { 
        const { message }= this.state;
        return (
            <section className="banner-section">
                <div className="container">

<div className="section-header"><br />            
<h2> 🔥 The future of dating with FlameCoin 🔥 </h2>
<p>Sign up to our mailing list and we will keep you up to date with our progress and opportunities to invest</p>
<div className="section-wrapper">
                        <div className="row align-items-end">
              
                        <section className="page-header-section style-1" >
                                <div className="banner-content">
                       
<div className="account-wrapper">
                            <form onSubmit={this.handleSubmit} className="account-form">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email_prereg"
                                        value={this.state.email}
                                        onChange={(e)=>{this.setState({email: e.target.value});}}
                                        placeholder="Email*"
                                    />
                                </div>
                               
                            
                                <div className="form-group">
                                    <button className="d-block lab-btn" type="submit"><span>Embrace the heat</span></button>
                                    <p>{message}</p>
                                </div>
                            </form>
                        </div>

                      


                    </div>
                    
                    </section>
                    <h3>Join the exclusive waiting list for Flame Coin and claim your 1,000 FREE COINS NOW!</h3>
                                  
                                </div>    </div>
                     
                        

                        

                            </div>
                        </div>
                  
          
            </section> 
        );
    }
}
 
export default PreRegistration;